<template>
  <div class="lg:flex lg:space-x-10">
    <div class="lg:px-20">
      <div class="grid grid-cols-2 md:grid-cols-3 gap-4" v-if="playlists.data && playlists.data.length">
        <div class="card relative" v-for="(playlist, index) in playlists.data" :key="'playlist' + index">
          <router-link :to="{name: 'PlaylistDetail', params: {id: playlist.id, slug: playlist.slug}}">
            <img :src="playlist.thumbnail ? playlist.thumbnail.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-1.jpg'"
                 class="h-44 object-cover rounded-t-md shadow-sm w-full">
          </router-link>
          <div class="p-3">
            <h4 class="text-base font-semibold mb-0.5 line-clamp-1">
              <router-link :to="{name: 'PlaylistDetail', params: {id: playlist.id, slug: playlist.slug}}">
                {{ playlist.title }}
              </router-link>
            </h4>
          </div>

          <div class="absolute top-2 right-2 md:inline hidden">
            <a href="#" class="hover:bg-gray-200 p-1.5 inline-block rounded-full" aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path>
              </svg>
            </a>
            <div class="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700 uk-drop uk-drop-bottom-right" uk-drop="mode: hover;pos: bottom-right" style="left: -188px; top: -12px;">
              <ul class="space-y-1">
                <li>
                  <router-link :to="{name: 'PlaylistEdit', params: {id: playlist.id}}" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                    <i class="uil-edit-alt mr-1"></i> Sửa
                  </router-link>
                </li>
                <li>
                  <a href="#" @click="open=true; setCurrentPlaylist(playlist)" class="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600">
                    <i class="uil-trash-alt mr-1"></i> Xóa
                  </a>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </div>

      <div :class="{'uk-open':open, 'uk-modal':true}" class="create-post is-story" :style="open ? 'display: block;':''" uk-modal>
        <div
            class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

          <div class="text-center py-3 border-b">
            <h3 class="text-lg font-semibold">Xóa playlist</h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" v-on:click="open = false" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
          </div>

          <div class="px-5 py-2">
            <div v-if="message" class="mb-2">
              <alert v-model:message="message" v-model:status="status"/>
            </div>
            <p>Bạn có chắc chắn muốn xóa playlist "<span class="font-semibold">{{ currentPlaylist.title }}</span>"?</p>
          </div>

          <div class="flex items-center w-full justify-center border-t p-3">
            <a href="javascript:void(0)" v-on:click="deletePlayList" class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
              Xóa playlist</a>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <v-pagination
            class="justify-content-center"
            v-if="playlists.paginatorInfo.lastPage > 1"
            v-model="playlists.paginatorInfo.currentPage"
            :pages="playlists.paginatorInfo.lastPage"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="loadPlaylists"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Alert from "../Alert";

export default {
  name: "PlaylistList",
  components: {
    VPagination, Alert
  },
  data() {
    return {
      playlists: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      message: "",
      status: "",
      open: false,
      currentPlaylist: {
        id: null,
        title: ""
      }
    }
  },
  methods: {
    loadPlaylists() {
      let query = `query($page: Int) {
        myPlaylists(first: 12, page: $page, orderBy: [{column: "id", order: DESC}]){
          data {
            id
            title
            slug
            thumbnail {
              url
            }
            user {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.playlists.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.myPlaylists) {
              this.playlists = data.data.myPlaylists;
              console.log("playlists.paginatorInfo.lastPage", this.playlists.paginatorInfo.lastPage);
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    deletePlayList() {
      let query = `mutation($id: ID!) {
        deletePlaylist(id: $id) {
          id
        }
      }`;

      ApiService.graphql(query, {id: this.currentPlaylist.id})
          .then(({data}) => {
            if (data.data && data.data.deletePlaylist) {
              this.open = false;
              this.loadPlaylists();
            } else {
              this.message = data.errors[0].message;
              this.status = "error";
            }
          })
          .catch((response) => {
            this.message = response.message;
            this.status = "error";
          });
    },
    setCurrentPlaylist(playlist) {
      this.currentPlaylist = playlist;
      this.message = "";
      this.status = "";
    }
  },
  mounted() {
    this.loadPlaylists();
  }
}
</script>
