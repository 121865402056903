<template>
  <div id="alert-modal" uk-modal>
    <div class="uk-modal-dialog">
      <button class="uk-modal-close-default m-3" type="button" uk-close></button>
      <div class="uk-modal-header">
        <h2 class="uk-modal-title">Thông báo</h2>
      </div>
      <div class="uk-modal-body">
        <p>{{ message }}</p>
      </div>
      <div class="uk-modal-footer text-right">
        <button class="button uk-modal-close" type="button">Tôi hiểu</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    message: String,
    status: String
  },
  methods: {
    closeMessage() {
      this.$emit("update:message", "");
      this.$emit("update:status", "");
    }
  }
}
</script>
